import cookies from 'js-cookie'
import platform from 'platform'
import { getStrYMDHMSS, getTodayTime, getDateFromIssueYmdt, getDiffYears } from '@/utils/dateUtils'
import config from '@/config'
import $ from 'jquery'

export function hasClass (elem, cls) {
  cls = cls || ''
  if (cls.replace(/\s/g, '').length === 0) return false
  return new RegExp(' ' + cls + ' ').test(' ' + elem.className + ' ')
}

export function addClass (elem, cls) {
  if (!hasClass(elem, cls)) {
    elem.className = elem.className === '' ? cls : elem.className + ' ' + cls
  }
}

export function removeClass (elem, cls) {
  if (hasClass(elem, cls)) {
    let newClass = ' ' + elem.className.replace(/[\t\r\n]/g, '') + ' '
    while (newClass.indexOf(' ' + cls + ' ') >= 0) {
      newClass = newClass.replace(' ' + cls + ' ', ' ')
    }
    elem.className = newClass.replace(/^\s+|\s+$/g, '')
  }
}

export function formatNumber (num) {
  const str = String(num.toFixed(0))
  return str.replace(/(\d)(?=(\d{3})+$)/g, '$1,')
}

export function miliFormat (num) {
  return num && num.toString().replace(/(?=(?!^)(\d{3})+$)/g, ',')
}

export function isPCDevice () {
  return /(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent) === false
}

export function logoutRemoveCookie () {
  cookies.remove('memberStatus')
  cookies.remove('ncpAccessToken')
  cookies.remove('buywowAccessToken')
  cookies.remove('oldAccessToken')
  cookies.remove('accessToken')
  cookies.remove('ncpMemberId')
  cookies.remove('ncpOauthIdNo')
  cookies.remove('ncpCertificated')
  cookies.remove('ncpMemberAgree')
  cookies.remove('oldOrder')
  cookies.remove('ncpAdultCertificated')
  cookies.remove('memberInBodyAppLogin')
}

export function scriptLoader (url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url
    script.async = true
    script.addEventListener('load', resolve)
    script.addEventListener('error', reject)
    document.body.appendChild(script)
  })
}

export function gotoOutLink (url) {
  if (process.env.BUILD_TARGET === 'webview') {
    window.location.href = 'buywow://newBrowser?url=' + encodeURIComponent(url)
  } else {
    window.open(url)
  }
}

export function formatBanners (banners) {
  if (banners) {
    return banners.filter(banner => getStrYMDHMSS(banner.displayStartYmdt) <= getTodayTime() && getTodayTime() <= getStrYMDHMSS(banner.displayEndYmdt))
  }
}

export function hasAccessToken () {
  return (cookies.get('ncpAccessToken') && cookies.get('ncpAccessToken').length > 0)
}

export function hasLastAccessToken () {
  return (cookies.get('ncpLastAccessToken') && cookies.get('ncpLastAccessToken').length > 0)
}

export function hasAccessTokenCopy () {
  return (cookies.get('accessToken') && cookies.get('accessToken').length > 0) ||
    (cookies.get('buywowAccessToken') && cookies.get('buywowAccessToken').length > 0)
}

export function getAccessToken () {
  if (cookies.get('buywowAccessToken') && cookies.get('buywowAccessToken').length > 0) {
    return cookies.get('buywowAccessToken')
  } else if (cookies.get('accessToken') && cookies.get('accessToken').length > 0) {
    return cookies.get('accessToken')
  } else {
    return ''
  }
}
export function loadTGScript (data, flag) {
  window.wptg_tagscript_vars = window.wptg_tagscript_vars || []
  if (window.wptg_tagscript_vars && window.wptg_tagscript_vars.length === 0) {
    window.wptg_tagscript_vars.push(function () {
      if (flag === 'cart') {
        let arry = data.map((item) => {
          return { i: item.productNo, t: item.productName }
        })
        return {
          wp_hcuid: '', /* 고객넘버 등 Unique ID (ex. 로그인  ID, 고객넘버 등 )를 암호화하여 대입.  *주의 : 로그인 하지 않은 사용자는 어떠한 값도 대입하지 않습니다. */
          ti: '46686', /* 광고주 코드 */
          ty: 'Cart', /* 트래킹태그 타입 */
          device: 'mobile', /* 디바이스 종류  (web 또는  mobile) */
          items: arry
        }
      }
      if (flag === 'product') {
        return {
          wp_hcuid: '', /* 고객넘버 등 Unique ID (ex. 로그인  ID, 고객넘버 등 )를 암호화하여 대입.  *주의 : 로그인 하지 않은 사용자는 어떠한 값도 대입하지 않습니다. */
          ti: '46686', /* 광고주 코드 */
          ty: 'Item', /* 트래킹태그 타입 */
          device: 'mobile', /* 디바이스 종류  (web 또는  mobile) */
          items: [{ i: data.pCode, t: data.pName }]
        }
      }

      if (flag === 'OrderDetail') {
        let orderArry = data.map((item) => {
          return { i: item.pCode, t: item.pName, p: item.pPrice, q: item.pQuantity }
        })
        return {
          wp_hcuid: '', /* 고객넘버 등 Unique ID (ex. 로그인  ID, 고객넘버 등 )를 암호화하여 대입.  *주의 : 로그인 하지 않은 사용자는 어떠한 값도 대입하지 않습니다. */
          ti: '46686', /* 광고주 코드 */
          ty: 'PurchaseComplete', /* 트래킹태그 타입 */
          device: 'mobile', /* 디바이스 종류  (web 또는  mobile) */
          items: orderArry
        }
      }
    })
  }
  scriptLoader('//cdn-aitg.widerplanet.com/js/wp_astg_4.0.js').then(() => {
    if (window.wptg_tagscript_history) {
      window.wptg_tagscript_history.history_peak = {}
      window.wptg_tagscript_history.history_ty = {}
    }
    if (window.wptg_tagscript) {
      window.wptg_tagscript.exec()
    }
  })
}
export function historyPage () {
  if ((window.location.pathname === '/' && cookies.get('memberInBodyAppLogin') === 'true') || config.isServiceMaintenance) {
    console.log('EXIT')
    commonAppCall('EXIT')
  } else if (window.location.search.indexOf('direct=1') > -1) {
    if (window.location.search.indexOf('calllocation=hotdeal') > -1) {
      location.href = '/lbevent/lbhotdealpage?direct=1'
    } else if (window.location.search.indexOf('calllocation=new') > -1) {
      location.href = '/lbEvent/lbnewproductpage?direct=1'
    } else if (window.location.search.indexOf('calllocation=communitydetail') > -1 ||
    window.location.search.indexOf('calllocation=dietmealdetail') > -1 ||
    window.location.search.indexOf('calllocation=communityhome') > -1 ||
    window.location.search.indexOf('calllocation=diary') > -1) {
      // 커뮤니티 홈 > 뭐먹지 상세로 바로 진입하는 경우 뒤로가기시 뭐먹지 목록을 띄운다.
      // 230407 희준님 요청으로 주석처리
      // if (location.pathname.indexOf('dietMealDetail') > -1) {
      //   let dietmealPageURL = window.location.origin + '/LBIntegratedSearch/LBdietmealpage'

      //   const urlParams = new URLSearchParams(window.location.search)
      //   urlParams.delete('mallProductNo')
      //   urlParams.delete('dietMealWhatToEatIDX')

      //   let queryStr = urlParams.toString()
      //   if (queryStr !== undefined && queryStr !== null && queryStr !== '') {
      //     dietmealPageURL += '?' + queryStr
      //   }

      //   location.href = dietmealPageURL
      // } else 
      if (window.location.href.indexOf('LBIntegratedSearch/LBSearchPage') > -1) {
        $('.back-btn').trigger('click')
      } else {
        console.log('EXIT')
        commonAppCall('EXIT')
      }
    } else {
        location.href = '/'
    }
  } else {
    // 본인인증 완료 후 진입된 페이지에서 백버튼 클릭시 본인인증 화면으로 되돌아가는 이슈로인해 메인으로 이동시킨다.
    if (window.location.search.indexOf('key=') > -1) {
      location.href = '/'
    } else if (window.location.href.indexOf('member/Certification') > -1) { // 본인인증 페이지에서 뒤로가기시
      var historyLength = cookies.get('HistoryLengthForCertification')
      historyLength = historyLength === undefined ? history.length - 1 + '' : historyLength

      // 선택된 상품정보 삭제
      cookies.remove('AfterCertificationPageIsDetail')
      cookies.remove('AfterCertificationProductNo')
      cookies.remove('HistoryLengthForCertification')

      var backCnt = history.length - parseInt(historyLength)
      backCnt = backCnt <= 0 ? 1 : backCnt
      history.go(-backCnt)
    } else if (window.location.href.indexOf('LBIntegratedSearch/LBSearchPage') > -1) {
      $('.back-btn').trigger('click')
    } else {
      history.back()
    }
  }
}
export function exitMarket () {
  console.log('EXIT')
  commonAppCall('EXIT')
}
export function appOutLink (outLinkUrl) {
  if (cookies.get('memberInBodyAppLogin') === 'true') {
    let data = {
      'ViewCode': 3,
      'OutLinkURL': outLinkUrl
    }
    console.log(JSON.stringify(data))
    commonAppCall(data, true)
  } else {
    window.open(outLinkUrl, '_blank')
  }
}
export function appLanding (rendingInfo) {
  let data = {
    'ViewCode': rendingInfo.viewCode,
    'RendingCode': rendingInfo.rendingCode,
    'RendingData': rendingInfo.rendingData
  }

  console.log(JSON.stringify(data))
  commonAppCall(data, true)
}
export function commonAppCall (data, isAllowStringify) {
  let userAgent = navigator.userAgent.toLocaleLowerCase()
  let jsonStringfyData = null

  if (isAllowStringify) {
    jsonStringfyData = JSON.stringify(data)
  } else {
    jsonStringfyData = data
  }

  if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1) {
    if (!config.isLocalDevelopment)
      return window.webkit.messageHandlers.handleAppMarket.postMessage(jsonStringfyData)
    else {
      console.log(jsonStringfyData)
      return
    }
  } else {
    return window.handleAppMarket.callAndroid(jsonStringfyData)
  }
}
export function isAppleDevice () {
  let userAgent = navigator.userAgent.toLocaleLowerCase()
  if (userAgent.indexOf('iphone') > -1 || userAgent.indexOf('ipad') > -1) {
    return true
  } else {
    return false
  }
}
export function showAlertWithOneButton (context, msg, okCallback) {
  // if (isAppleDevice()) {
    context.$swal({
      title: msg,
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: '확인'
    }).then(() => {
      if (okCallback === null || typeof okCallback !== 'function') {
        console.log('okCallback is null or not function.')
        return
      }
      okCallback()
    })
  // } else {
  //   alert(msg)
  // }
}
export function showAlertWithTwoButton (context, msg, okCallback, cancelCallback) {
  // if (isAppleDevice()) {
    context.$swal({
      title: msg,
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: '확인',
      denyButtonText: '취소',
    }).then((result) => {
      if (result.isConfirmed) {
        // 확인
        console.log('iosConfirm 확인')
        if (okCallback === null || typeof okCallback !== 'function') {
          console.log('okCallback is null or not function.')
          return
        }
        okCallback()
      } else {
        // 취소
        console.log('iosConfirm 취소')
        if (cancelCallback === null || typeof cancelCallback !== 'function') {
          console.log('cancelCallback is null or not function.')
          return
        }
        cancelCallback()
      }
    })
  // } else {
  //   if (confirm(msg)) {
  //     if (okCallback === null || typeof okCallback !== 'function') {
  //       console.log('okCallback is null or not function.')
  //       return
  //     }
  //     okCallback()
  //   } else {
  //     if (cancelCallback === null || typeof cancelCallback !== 'function') {
  //       console.log('cancelCallback is null or not function.')
  //       return
  //     }
  //     cancelCallback()
  //   }
  // }
}
export function showLoading (isShow) {
  if (document.getElementById('dimloader')) {
    if (!isShow) {
      document.getElementById('dimloader').style.display = 'none'
    } else {
      document.getElementById('dimloader').style.display = 'block'
    }
  }
}
// 성인인증 체크가 필요한지 여부 : birthday 값이 null이라는건 본인인증을 진행하지 않았다는 것을 의미
export function checkNeedAdultCert () {
  let birthday = cookies.get('birthday')
  if (birthday === undefined || birthday === null) {
    return true
  }

  let dateToday = new Date()
  let lastAdultCertDate = cookies.get('lastAdultCertDate')
  let dateLastAdultCertDate = lastAdultCertDate === undefined || lastAdultCertDate === null ? new Date() : getDateFromIssueYmdt(lastAdultCertDate)
  let yearDiff = getDiffYears(dateToday, dateLastAdultCertDate)

  // 마지막 본인인증 일시가 일년이 넘은 경우 본인인증 다시하도록 한다.
  if (lastAdultCertDate === undefined || lastAdultCertDate === null || yearDiff >= 1) {
    return true
  } else {
    return false
  }
}
// 성인여부 확인
export function checkIsAdult () {
  let birthday = cookies.get('birthday')
  if (birthday === undefined || birthday === null) {
    return false
  }

  let dateToday = new Date()
  let dateBirthday = getDateFromIssueYmdt(birthday + ' 12:00:00')

  let nowYear = dateToday.getFullYear()
  let birthYear = dateBirthday.getFullYear()

  let age = nowYear - birthYear

  /**
   * 청소년보호법에 따라 만나이로 19세가 되는 년도의 1월 1일부터 성인인증이 가능
   * 예) 2022년 기준
   * 2003년 출생: 성인인증 가능
   * 2004년, 2005년, … 출생: 성인인증 불가
   */

  if (age >= 19) {
    return true
  } else {
    return false
  }
}

export function getQueryValue (windowObj, key) {
  var tempIsFromAdultCert

  if (windowObj) {
    // query string 중 %가 들어간 key-value 추출
    const urlParams = new URLSearchParams(windowObj.location.search)
    if (urlParams.has(key)) {
      tempIsFromAdultCert = urlParams.get(key)
    }
  }

  return tempIsFromAdultCert
}

// 성인인증 완료 후 여부 체크 및 화면 랜딩 (모바일용 본인인증 화면이 iframe에서 띄워줘야하는 기술적이슈가 있으므로 인증 완료 후 iframe을 제거하고 부모의 href를 변경한다.)
export function checkIsFromAdultCert (windowObj, isFromAdultCert) {
  if (windowObj) {
    if (isFromAdultCert === '1') {
      const urlParams = new URLSearchParams(windowObj.location.search)
      urlParams.delete('isFromAdultCert')

      // return Url 생성
      var returnUrl = windowObj.location.origin + windowObj.location.pathname
      let queryStr = urlParams.toString()
      if (queryStr !== undefined && queryStr !== null && queryStr !== '') {
          returnUrl += '?' + queryStr
      }

      console.log('본인인증 완료 후 ' + returnUrl)
      windowObj.parent.postMessage(
        // 전달할 data (부모창에서 호출할 함수명)
        { functionName: 'certComplete',
        params: [ returnUrl ]}
        // 부모창의 도메인
        // , 'http://abc.com'
        // 모든 도메인에 대하여 허용 ( 추천하지 않음 )
        // , '*'
        , windowObj.location.origin
      )
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

// 현재 URL의 쿼리스트링에 특정 key값이 있는지 확인하고, 이를 쿼리스트링으로 추출해서 반환
export function getQueryString (keys) {
  var queryStr = ''
  const urlParams = new URLSearchParams(window.location.search)

  keys.forEach(element => {
    if (urlParams.has(element)) {
      if (queryStr === '') {
        queryStr += element + '=' + urlParams.get(element)
      } else {
        queryStr += '&' + element + '=' + urlParams.get(element)
      }
    }
  })

  return queryStr
}

export function dataURLtoFile (dataurl, fileName) {
  var arr = ''
  var mime = ''
  var bstr = ''
  var n = ''
  var u8arr = ''

  try {
      arr = dataurl.split(',')
      mime = arr[0].match(/:(.*?);/)[1]
      bstr = atob(arr[1])
      n = bstr.length
      u8arr = new Uint8Array(n)
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
      }
      if (u8arr.length === 0 || u8arr.length !== bstr.length) {
          return null
      }
  } catch (e) {
      return null
  }
  return new File([u8arr], fileName, {type: mime})
}

export function getReviewRewardAmt (price) {
  return Math.round(price * 0.01)
}

export function getReviewRewardTotalAmt (price) {
  return Math.round(price * 0.01) + 50
}

export function checkUsableFunctionWithOSVersion (iosRefAppVersion, aosRefAppVersion) {
  let isAble = false
  try {
    const fullappversion = cookies.get('appVersion')
    const apptype = cookies.get('appType').toLowerCase()
    let appversion = fullappversion
    if (apptype !== 'ios') {
      if (fullappversion.indexOf('(') > -1) {
        appversion = fullappversion.split('(')[0]
      } else if (fullappversion.indexOf('_') > -1) {
        appversion = fullappversion.split('_')[0]
      }
    }

    let refAppVersion = (apptype !== 'ios' ? aosRefAppVersion : iosRefAppVersion)
    let arrAppVersion = appversion.split('.')
    for (let i = 0; i < arrAppVersion.length; i++) {
      let value = arrAppVersion[i]
      if (Number(value) > refAppVersion[i]) {
        isAble = true
        break
      } else if (Number(value) === refAppVersion[i]) {
        if (i === arrAppVersion.length - 1) isAble = true
        else continue
      } else {
        isAble = false
        break
      }
    }
  } catch (e) {
    console.log('e : ' + e)
  }

  return isAble
}

// query string 기본 정보 저장
export function saveUserBaseInfo (to) {
  // App 정보는 만료시간 360일 후 설정
  const expAppInfo = new Date()
  expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * 30 * 12)

  let appType = to.query.appType
  if (appType !== undefined && appType !== null && appType !== '') {
    cookies.set('appType', appType, { 'expires': expAppInfo })
  }

  let appVersion = to.query.appVersion
  if (appVersion !== undefined && appVersion !== null && appVersion !== '') {
    cookies.set('appVersion', appVersion, { 'expires': expAppInfo })
  }

  let uId = to.query.uId
  if (uId !== undefined && uId !== null && uId !== '') {
    cookies.set('memberCummunityUID', uId, { 'expires': expAppInfo })
  }
}

/**
 * 유효기간 1년으로 쿠키값 저장
 * @param { String } key 
 * @param { * } value 
 */
export function setCookieForYear (key, value) {
  // App 정보는 만료시간 360일 후 설정
  const expAppInfo = new Date();
  expAppInfo.setTime(expAppInfo.getTime() + 1000 * 60 * 60 * 24 * 30 * 12)
  cookies.set(key, value, { 'expires': expAppInfo })
}